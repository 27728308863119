<i18n>
{
  "de": {
    "pageTitle": "Szenario",
    "scenarios": "Szenarien",
    "newScenario": "Neues Szenario"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="edit-scenario-page">
    <template #header>
      <PageTitle
        :title="model.description || '-'"
        :portfolio="portfolio"
        :breadcrumbs="[{ path: `/portfolios/${portfolio.id}/scenarios`, label: $t('scenarios') }]"
      >
        <template #icon>
          <ScenarioIcon v-if="scenario" :color="scenario.color" size="20" />
        </template>
      </PageTitle>
    </template>

    <template #default>
      <EditContainer
        :bind-form-state="formState"
        :allow-edit="getPortfolioPermission('EDIT_SCENARIOS')"
        @container="onFormEvent"
      >
        <EditDescription v-model="model.description" :edit="isEditing" />
        <EditEnvelopeScenario
          v-if="portfolio.enabled_features.ENVELOPE_MEASURES"
          v-model="model.definition.envelope"
          :edit="isEditing"
          :portfolio="portfolio"
        />
        <EditHeatingScenario
          v-if="portfolio.enabled_features.HEATING_MEASURES"
          v-model="model.definition.heating"
          :edit="isEditing"
        />
        <EditPvScenario v-if="portfolio.enabled_features.PV_MEASURES" v-model="model.definition.pv" :edit="isEditing" />
      </EditContainer>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

import FormStateMixin from '@/components/shared/forms/FormStateMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import ScenarioIcon from '@/components/shared/ScenarioIcon.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import ScenarioPageMixin from '@/pages/vuex-mixins/ScenarioPageMixin.vue'

import EditDescription from '@/components/scenarios/edit/EditDescription.vue'
import EditEnvelopeScenario from '@/components/scenarios/edit/EditEnvelopeScenario.vue'
import EditHeatingScenario from '@/components/scenarios/edit/EditHeatingScenario.vue'
import EditPvScenario from '@/components/scenarios/edit/EditPvScenario.vue'
import Scenario from '@/models/Scenario'

export default {
  name: 'scenario',

  mixins: [
    FormStateMixin,
    /* FormStateMixin:
      - Requires implementation of the following methods: resetModel, saveHandler
      - Optional implementations: cancelHandler, isValid
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    ScenarioPageMixin, // Provides: scenario
  ],

  components: {
    EditContainer,
    MainLayout,
    PageTitle,
    ScenarioIcon,
    EditDescription,
    EditEnvelopeScenario,
    EditHeatingScenario,
    EditPvScenario,
  },

  watch: {
    scenario() {
      this.resetModel()
    },
  },

  created() {
    if (this.$route.params.scenario_id === '-1') {
      this.setEditMode(true)
    }
  },

  beforeDestroy() {
    this.resetModel()
  },

  methods: {
    ...mapActions({
      addScenario: 'portfolio/addScenario',
      updateScenario: 'portfolio/updateScenario',
    }),

    // Load/reset scenario parameters from structure
    resetModel() {
      const { id, description, definition } = _.cloneDeep(this.scenario || {})

      this.model = {
        id: id || null,
        portfolio_id: this.$route.params.portfolio_id,
        description: description || this.$t('newScenario'),
        definition: definition || new Scenario(),
      }
    },

    // Submit scenario changes to API
    async saveHandler(model) {
      if (model.id === null) {
        // eslint-disable-next-line camelcase
        const { definition, description, portfolio_id } = model
        const result = await this.addScenario({ definition, description, portfolio_id })

        if (result) {
          this.$router.push({ name: 'scenario', params: { portfolio_id, scenario_id: result.id } })
        }
      } else {
        await this.updateScenario(model)
      }
    },

    cancelHandler() {
      if (!this.scenario) {
        // Go back to the scenarios page if editing a new scenario
        this.$router.push({ name: 'scenarios', params: { portfolio_id: this.model.portfolio_id } })
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
